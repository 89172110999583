import Card, { ICardProps } from "./Card";
import styles from "./Card.module.scss";

interface ICardsProps {
  children: React.ReactElement<ICardProps> | React.ReactElement<ICardProps>[];
}

function Cards(props: ICardsProps) {
  return <div className={styles.cards}>{props.children}</div>;
}

export default Cards;
