import {
  email,
  footerLinks,
  footerText,
  telephone,
} from "../Constants/Strings";
import styles from "./Footer.module.scss";
import {
  faCopyright,
  faPhone,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../Images/LogoAlt.png";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.left}>
          <img
            src={logo}
            className={styles.logo}
            alt="Wheelwrights Accounting Limited"
          />
          <p>
            <FontAwesomeIcon icon={faClock} /> Monday-Friday: 9am-5pm
          </p>
          <div className={styles.contact}>
            <a href={`tel:+${telephone}`}>
              <FontAwesomeIcon icon={faPhone} /> {telephone}
            </a>
            <a href={`mailto:${email}`}>
              <FontAwesomeIcon icon={faEnvelope} /> {email}
            </a>
          </div>
          <p>{footerText}</p>
        </div>
        <div className={styles.right}>
          <div className={styles.links}>
            <p>
              <strong>Useful links</strong>
            </p>
            {footerLinks.map((x) => {
              return (
                <div key={`footer-link-${x.name}`}>
                  <a href={x.url}>{x.name}</a>
                </div>
              );
            })}
          </div>
          <small>
            <FontAwesomeIcon icon={faCopyright} /> 2022 The Wheelwrights
            Limited. All rights reserved.
          </small>
        </div>
        <small>
          Website developed by{" "}
          <a
            href="https://shanesmith.azurewebsites.net/"
            target="_blank"
            rel="noreferrer"
          >
            Shane Smith
          </a>
        </small>
      </div>
    </footer>
  );
}

export default Footer;
