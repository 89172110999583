import PageSection from "../Components/PageSection";
import styles from "./Site.module.scss";
import welcome from "../Images/work.png";

function SiteMap() {
  return (
    <div className={styles.siteMapSite}>
      <PageSection backgroundImageUrl={welcome} gutterColour="#8c5523">
        <div className={styles.title}>
          <h1>Site map</h1>
        </div>
      </PageSection>
      <PageSection>
        <ul className={styles.siteMap}>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/services">Services</a>
          </li>
          <ul>
            <li>
              <a href="/services/overview">Overview</a>
            </li>
            <li>
              <a href="/services/accounting-services">Accounting Services</a>
            </li>
            <li>
              <a href="/services/company-secretarial">Company Secretarial</a>
            </li>
            <li>
              <a href="/services/business-support">Business Support</a>
            </li>
            <li>
              <a href="/services/financial-management">Financial Management</a>
            </li>
          </ul>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
          <li>
            <a href="/legals">Legals</a>
          </li>
        </ul>
      </PageSection>
    </div>
  );
}

export default SiteMap;
