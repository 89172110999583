import { useId } from "react";
import { NavLink, To } from "react-router-dom";
import styles from "./Navigation.module.scss";

interface INavItemProps {
  to: To;
  label: string;
  right?: boolean;
}

function NavItem(props: INavItemProps) {
  const id = useId();

  const className = props.right
    ? `${styles.navItem} ${styles.right}`
    : `${styles.navItem}`;

  return (
    <div id={id} className={className}>
      <NavLink to={props.to}>{props.label}</NavLink>
    </div>
  );
}

export default NavItem;
