import styles from "./ListGroup.module.scss";
import ListGroupItem from "./ListGroupItem";

interface IListGroupProps {
  children:
    | React.ReactElement<typeof ListGroupItem>
    | React.ReactElement<typeof ListGroupItem>[];
  isDark?: boolean;
}

function ListGroup(props: IListGroupProps) {
  const className = props.isDark
    ? `${styles.listGroup} ${styles.isDark}`
    : styles.listGroup;

  return <ul className={className}>{props.children}</ul>;
}

export default ListGroup;
