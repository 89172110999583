import PageSection from "../Components/PageSection";
import { address, email, telephone } from "../Constants/Strings";
import styles from "./Site.module.scss";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ContactForm from "../Components/ContactForm";

function Contact() {
  return (
    <div className={styles.contact}>
      <PageSection>
        <h1>Please feel free to get in touch...</h1>
        <div className={styles.details}>
          <a href={`tel:+${telephone}`} className={styles.detailsCard}>
            <p>
              <FontAwesomeIcon icon={faPhone} />
              {telephone}
            </p>
          </a>
          <a href={`mailto:${email}`} className={styles.detailsCard}>
            <p>
              <FontAwesomeIcon icon={faEnvelope} />
              {email}
            </p>
          </a>
          <div className={styles.detailsCard}>
            <p>
              <FontAwesomeIcon icon={faClock} /> Monday-Friday: 9am-5pm
            </p>
          </div>
          <div className={styles.detailsCard}>
            <p>
              <FontAwesomeIcon icon={faLocationDot} /> {address}
            </p>
          </div>
        </div>
        <ContactForm />
      </PageSection>
      <PageSection>
        <div className={styles.map}>
          <iframe
            title="Google Maps"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="eager"
            allow="fullscreen"
            src="https://www.google.com/maps/embed/v1/place?q=2%2C%20The%20Wheelwrights%2C%20Blackham%2C%20Tunbridge%20Wells%2C%20East%20Sussex%20TN3%209TS%2C%20UK&key=AIzaSyD6sNifYSA-2EYkPeVqUSCcDvsIXojISQo"
          ></iframe>
        </div>
      </PageSection>
    </div>
  );
}

export default Contact;
