interface IDescriptiveListItemProps {
  id?: string | undefined;
  title: string;
  children: string | React.ReactElement | React.ReactElement[];
}

function DescriptiveListItem(props: IDescriptiveListItemProps) {
  return (
    <>
      <dt id={props.id}>{props.title}</dt>
      <dd>{props.children}</dd>
    </>
  );
}

export default DescriptiveListItem;
