import { faPhone } from "@fortawesome/free-solid-svg-icons";
import CallToAction from "../../Components/CallToAction";
import PageSection from "../../Components/PageSection";
import banner from "../../Images/ServicesBanner.png";
import styles from "./Services.module.scss";

function AccountingServices() {
  return (
    <div className={styles.accountingServices}>
      <PageSection
        backgroundImageUrl={banner}
        className={styles.servicesBackground}
        gutterColour="#999999"
      >
        <div className={styles.title}>
          <h1>Accounting Services</h1>
          <p>
            Let us provide you with more time to concentrate on running your
            business and peace of mind that your accounts and returns are in
            order.
          </p>
        </div>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.page}>
          <div className={styles.left}>
            <h3>Our Company Secretarial services include:</h3>
            <ul>
              <li>Preparation of annual accounts</li>
              <li>Preparation of periodic management accounts</li>
              <li>Book-keeping services</li>
              <li>Maintaining PAYE and VAT records and associated returns</li>
              <li>Preparation for HMRC investigations</li>
              <li>PAYE and National Insurance compliance</li>
              <li>Self-Assessment advice</li>
              <li>Corporation Tax</li>
            </ul>
          </div>
          <div className={styles.right}>
            <CallToAction
              to="/contact"
              title="Contact Us"
              description="Get in touch today and let us help you"
              icon={faPhone}
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
}

export default AccountingServices;
