import { faPhone } from "@fortawesome/free-solid-svg-icons";
import CallToAction from "../../Components/CallToAction";
import PageSection from "../../Components/PageSection";
import banner from "../../Images/ServicesBanner.png";
import styles from "./Services.module.scss";

function CompanySecretarial() {
  return (
    <div className={styles.companySecretarial}>
      <PageSection
        backgroundImageUrl={banner}
        className={styles.servicesBackground}
        gutterColour="#999999"
      >
        <div className={styles.title}>
          <h1>Company Secretarial</h1>
          <p>
            The Companies Act places strict obligations on companies to submit
            the required documents according to deadlines. We can provide peace
            of mind, by taking the hard work off your hands.
          </p>
        </div>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.page}>
          <div className={styles.left}>
            <h3>Our Company Secretarial services include:</h3>
            <ul>
              <li>Preparation and filing of statutory returns</li>
              <li>Preparation of minutes and resolutions</li>
              <li>Company formation</li>
              <li>Company searches</li>
            </ul>
          </div>
          <div className={styles.right}>
            <CallToAction
              to="/contact"
              title="Contact Us"
              description="Get in touch today and let us help you"
              icon={faPhone}
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
}

export default CompanySecretarial;
