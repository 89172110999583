import { faPhone } from "@fortawesome/free-solid-svg-icons";
import CallToAction from "../../Components/CallToAction";
import PageSection from "../../Components/PageSection";
import banner from "../../Images/ServicesBanner.png";
import styles from "./Services.module.scss";

function BusinessSupport() {
  return (
    <div className={styles.businessSupport}>
      <PageSection
        backgroundImageUrl={banner}
        className={styles.servicesBackground}
        gutterColour="#999999"
      >
        <div className={styles.title}>
          <h1>Business Support</h1>
          <p>
            Our support for you stretches far beyond the essential compliance
            services. We aim to be your business partners, working with you to
            ensure the best possible chance of success.
          </p>
        </div>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.page}>
          <div className={styles.left}>
            <h3>
              We want to help improve your bottom line, not just tell you what
              it is!
            </h3>
            <h3>Areas where we can assist you include:</h3>
            <ul>
              <li>Business start-up planning and advice</li>
              <li>Raising business finance</li>
              <li>Strategic planning</li>
              <li>Financial management</li>
              <li>Financial information systems</li>
              <li>Computer systems advice</li>
              <li>Cashflow forecasting and management</li>
              <li>Efficient billing and cash collection</li>
              <li>Long term plans for your business and your exit</li>
            </ul>
            <p>...and much more.</p>
          </div>
          <div className={styles.right}>
            <CallToAction
              to="/contact"
              title="Contact Us"
              description="Get in touch today and let us help you"
              icon={faPhone}
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
}

export default BusinessSupport;
