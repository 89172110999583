import PageSection from "../Components/PageSection";
import styles from "./Site.module.scss";
import banner from "../Images/work.png";
import ListGroup from "../Components/ListGroup";
import ListGroupItem from "../Components/ListGroupItem";
import DescriptiveList from "../Components/DescriptiveList";
import DescriptiveListItem from "../Components/DescriptiveListItem";

function Legals() {
  return (
    <div className={styles.legals}>
      <PageSection backgroundImageUrl={banner} gutterColour="#8c5523">
        <div className={styles.title}>
          <h1>Legals</h1>
          <h3>
            While we have made every effort to provide accurate and up to date
            information on our website, the law is constantly changing and
            affects each person or business in different ways.
          </h3>
          <p>
            The information contained within this website is no substitute for
            specific advice and we will not accept liability if you rely solely
            on information from the website.
          </p>
          <p>
            <strong>
              It is important that you read this page as it contains important
              information regarding accessing our website.
            </strong>
          </p>
          <p>
            By accessing this website you agree to the terms and conditions set
            out below:
          </p>
        </div>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.col6}>
          <div>
            <h2 id="general">General</h2>
            <ul>
              <li>
                This website is published by Wheelwrights Accounting Limited.
                The following statements apply to this website.
              </li>
              <li>
                This website is issued in the United Kingdom and is intended for
                the information and use of United Kingdom residents only.
              </li>
            </ul>
          </div>
          <div>
            <ListGroup isDark>
              <ListGroupItem title="General" href="#general" />
              <ListGroupItem title="Privacy" href="#privacy" />
              <ListGroupItem title="Content" href="#content" />
              <ListGroupItem title="Downloads" href="#downloads" />
              <ListGroupItem title="Liability" href="#liability" />
              <ListGroupItem title="Emails" href="#emails" />
            </ListGroup>
          </div>
        </div>
      </PageSection>
      <PageSection>
        <h2 id="privacy">Privacy</h2>
        <p>
          Wheelwrights Accounting Limited respects your privacy and is committed
          to protecting your personal data. This privacy notice will inform you
          as to how we look after your personal data when you visit our website
          (regardless of where you visit it from) and tell you about your
          privacy rights and how the law protects you.
        </p>
        <div className={styles.col6}>
          <ListGroup>
            <ListGroupItem
              title="Important information and who we are"
              href="#important-information"
            />
            <ListGroupItem
              title="The data we collect about you"
              href="#data-collected"
            />
            <ListGroupItem
              title="How is your personal data collected?"
              href="#personal-data-collected"
            />
            <ListGroupItem
              title="How we use your personal data"
              href="#use-personal-data"
            />
            <ListGroupItem
              title="Disclosures of your personal data"
              href="#disclosures-personal-data"
            />
          </ListGroup>
          <ListGroup>
            <ListGroupItem
              title="International transfers"
              href="#international-transfers"
            />
            <ListGroupItem title="Data security" href="#data-security" />
            <ListGroupItem title="Data retention" href="#data-retention" />
            <ListGroupItem title="Your legal rights" href="#legal-rights" />
            <ListGroupItem title="Glossary" href="#glossary" />
          </ListGroup>
        </div>

        <h3 id="important-information">Important information and who we are</h3>

        <h4>Purpose of this privacy notice</h4>
        <p>
          This privacy notice aims to give you information on how Wheelwrights
          Accounting Limited collects and processes your personal data through
          your use of this website, including any data you may provide through
          this website.
        </p>
        <p>
          This website is not intended for children and we do not knowingly
          collect data relating to children.
        </p>
        <p>
          It is important that you read this privacy notice together with any
          other privacy notice or fair processing notice we may provide on
          specific occasions when we are collecting or processing personal data
          about you so that you are fully aware of how and why we are using your
          data. This privacy notice supplements the other notices and is not
          intended to override them.
        </p>

        <h4>Controller</h4>
        <p>
          Wheelwrights Accounting Limited is the controller and responsible for
          your personal data (collectively referred to as Wheelwrights
          Accounting Limited, 'we', 'us' or 'our' in this privacy notice). We
          have appointed a data privacy manager who is responsible for
          overseeing questions in relation to this privacy notice. If you have
          any questions about this privacy notice, including any requests to
          exercise your legal rights, please contact the data privacy manager
          using the details set out below. You have the right to make a
          complaint at any time to the Information Commissioner's Office (ICO),
          the UK supervisory authority for data protection issues (
          <a href="https://www.ico.org.uk" target="_blank">
            www.ico.org.uk
          </a>
          ). We would, however, appreciate the chance to deal with your concerns
          before you approach the ICO so please{" "}
          <a href="/contact">contact us</a> in the first instance.
        </p>

        <h4>
          Changes to the privacy notice and your duty to inform us of changes
        </h4>
        <p>
          This version was last updated on 1st October 2022 and historic
          versions can be obtained by <a href="/contact">contacting us</a>.
        </p>
        <p>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your relationship with us.
        </p>

        <h4>Third-party links</h4>
        <p>
          This website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy notice of every website you visit.
        </p>

        <h3 id="data-collected">The data we collect about you</h3>
        <p>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).
        </p>
        <p>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:
        </p>
        <div className={styles.col6}>
          <DescriptiveList>
            <DescriptiveListItem title="Identity Data">
              Includes first name, maiden name, last name, username or similar
              identifier, marital status, title, date of birth and gender.
            </DescriptiveListItem>
            <DescriptiveListItem title="Contact Data">
              Includes company address, email address and telephone numbers.
            </DescriptiveListItem>
            <DescriptiveListItem title="Transaction Data">
              Includes details about payments to and from you and other details
              of the services you use from us.
            </DescriptiveListItem>
            <DescriptiveListItem title="Technical Data">
              Includes internet protocol (IP) address, your login data, browser
              type and version, time zone setting and location, browser plug-in
              types and versions, operating system and platform and other
              technology on the devices you use to access this website.
            </DescriptiveListItem>
          </DescriptiveList>
          <DescriptiveList>
            <DescriptiveListItem title="Profile Data">
              Includes your username and password, preferences, feedback and
              survey responses.
            </DescriptiveListItem>
            <DescriptiveListItem title="Usage Data">
              Includes information about how you use our website, products and
              services.
            </DescriptiveListItem>
            <DescriptiveListItem title="Marketing and Communications Data">
              Includes your preferences in receiving marketing from us and our
              third parties and your communication preferences.
            </DescriptiveListItem>
          </DescriptiveList>
        </div>
        <p>
          We do not collect any{" "}
          <strong>Special Categories of Personal Data</strong> about you (this
          includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </p>
        <h4>If you fail to provide personal data</h4>
        <p>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services). In this case, we may have to cancel a product or service
          you have with us but we will notify you if this is the case at the
          time.
        </p>

        <h3 id="personal-data-collected">
          How is your personal data collected?
        </h3>
        <p>
          We use different methods to collect data from and about you including
          through:
        </p>
        <div>
          <DescriptiveList>
            <DescriptiveListItem title="Direct interactions">
              <>
                You may give us your Identity / Contact data by filling in forms
                or by corresponding with us by post, phone, email or otherwise.
                This includes personal data you provide when you:
                <ul>
                  <li> apply for our products or services</li>
                  <li>create an account on our website</li>
                  <li>subscribe to our service or publications</li>
                  <li>request marketing to be sent to you</li>
                  <li>enter a survey</li>
                  <li>apply for employment or to provide us with services</li>
                  <li>give us some feedback.</li>
                </ul>
              </>
            </DescriptiveListItem>
            <DescriptiveListItem title="Automated technologies or interactions">
              As you interact with our website, we may automatically collect
              Technical Data about your equipment, browsing actions and
              patterns. We collect this personal data by using cookies, server
              logs and other similar technologies. Please see our cookie policy
              for further details.
            </DescriptiveListItem>
            <DescriptiveListItem title="Third parties or publicly available sources.">
              <>
                We may receive personal data about you from various third
                parties, including technical data from the following parties:
                <ul>
                  <li>
                    analytics providers [such as Google based outside the UK]
                  </li>
                  <li>
                    advertising networks [such as Google based outside the UK]
                  </li>
                  <li>
                    search information providers [such as Google based outside
                    the UK].
                  </li>
                </ul>
              </>
            </DescriptiveListItem>
          </DescriptiveList>
        </div>

        <h3 id="use-personal-data">How we use your personal data</h3>
        <p>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </p>
        <ul>
          <li>
            Where we need to perform the contract we are about to enter into or
            have entered into with you.
          </li>
          <li>
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests.
          </li>
          <li>
            Where we need to comply with a legal or regulatory obligation.
          </li>
        </ul>
        <p>
          Generally we do not rely on consent as a legal basis for processing
          your personal data other than in relation to sending third party
          direct marketing communications to you via email or text message. You
          have the right to withdraw consent to marketing at any time by{" "}
          <a href="/contact">contacting us</a>.
        </p>
        <h4>Purposes for which we will use your personal data</h4>
        <p>
          We have set out below a description of all the ways we plan to use
          your personal data, and which of the legal bases we rely on to do so.
          We have also identified what our legitimate interests are where
          appropriate.
        </p>
        <p>
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your
          data. Please <a href="/contact">contact us</a> if you need details
          about the specific legal ground we are relying on to process your
          personal data where more than one ground has been set out in the table
          below.
        </p>

        <table className={`${styles.tablePrivacy} table table-bordered`}>
          <thead>
            <tr>
              <th>Purpose/Activity</th>
              <th>Type of data</th>
              <th>
                Lawful basis for processing including basis of legitimate
                interest
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                To manage our relationship with you which will include:
                <ul>
                  <li>
                    Notifying you about changes to our terms or privacy policy
                  </li>
                  <li>Asking you to leave a review or take a survey</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Identity</li>
                  <li>Contact</li>
                  <li>Profile</li>
                  <li>Marketing and Communications</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Performance of a contract with you</li>
                  <li>Necessary to comply with a legal obligation</li>
                  <li>
                    Necessary for our legitimate interests (to keep our records
                    updated and to study how customers use our
                    products/services)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>To enable you to complete a survey</td>
              <td>
                <ul>
                  <li>Identity</li>
                  <li>Contact</li>
                  <li>Profile</li>
                  <li>Usage</li>
                  <li>Marketing and Communications</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Performance of a contract with you</li>
                  <li>
                    Necessary for our legitimate interests (to study how
                    customers use our products/services, to develop them and
                    grow our business)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                To administer and protect our business and this website
                (including troubleshooting, data analysis, testing, system
                maintenance, support, reporting and hosting of data)
              </td>
              <td>
                <ul>
                  <li>Identity</li>
                  <li>Contact</li>
                  <li>Technical</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    Necessary for our legitimate interests (for running our
                    business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise)
                  </li>
                  <li>Necessary to comply with a legal obligation</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                To use data analytics to improve our website, products/services,
                marketing, customer relationships and experiences
              </td>
              <td>
                <ul>
                  <li>Technical</li>
                  <li>Usage</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    Necessary for our legitimate interests (to define types of
                    customers for our products and services, to keep our website
                    updated and relevant, to develop our business and to inform
                    our marketing strategy)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                To make suggestions and recommendations to you about goods or
                services that may be of interest to you
              </td>
              <td>
                <ul>
                  <li>Identity</li>
                  <li>Contact</li>
                  <li>Technical</li>
                  <li>Usage</li>
                  <li>Profile</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    Necessary for our legitimate interests (to develop our
                    products/services and grow our business)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                To perform our services to you including:
                <ul>
                  <li>Manage payments, fees and charges</li>
                  <li>Collect and recover money owed to us</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Identity</li>
                  <li>Contact</li>
                  <li>Financial</li>
                  <li>Transaction</li>
                  <li>Marketing and Communications</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Performance of a contract with you</li>
                  <li>
                    Necessary for our legitimate interests (to recover debts due
                    to us)
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <h4>Marketing</h4>
        <p>
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising.
        </p>

        <h4>Promotional offers from us</h4>
        <p>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you. This is how we decide which products, services and
          offers may be relevant for you (we call this marketing).
        </p>
        <p>
          You will receive marketing communications from us if you have
          requested information from us or purchased services from us and, in
          each case, you have not opted out of receiving that marketing.
        </p>

        <h4>Third-party marketing</h4>
        <p>
          We will get your express opt-in consent before we share your personal
          data with any company outside Wheelwrights Accounting Limited group of
          companies for marketing purposes.
        </p>

        <h4>Opting out</h4>
        <p>
          You can ask us or third parties to stop sending you marketing messages
          at any time by following the opt-out links on any marketing message
          sent to you or by <a href="/contact">contacting us</a> at any time.
        </p>
        <p>
          Where you opt out of receiving these marketing messages, this will not
          apply to personal data provided to us as a result of a service
          purchase or other transactions.
        </p>

        <h4>Cookies</h4>
        <p>
          This website uses cookies. A cookie is a small text file stored in
          your computer containing text data. We use cookies for certain
          functions to improve the usability of the website. However, enabling
          cookies in your web browser is necessary if you wish your selections
          to be remembered for future visits on the same computer. You can set
          your browser to refuse all or some browser cookies, or to alert you
          when websites set or access cookies. For more information about
          cookies and instructions on how to adjust your browser settings to
          restrict or disable cookies, see the IAB website at{" "}
          <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.
          If you disable or refuse cookies, please note that some parts of this
          website may become inaccessible or not function properly.
        </p>

        <h4>Change of purpose</h4>
        <p>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please{" "}
          <a href="/contact">contact us</a>.
        </p>
        <p>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
        </p>
        <p>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </p>

        <h3 id="disclosures-personal-data">
          Disclosures of your personal data
        </h3>
        <p>
          We may have to share your personal data with the parties set out below
          for the purposes set out in the table in ' How we use your personal
          data':
        </p>
        <ul>
          <li>Internal Third Parties as set out in the Glossary.</li>
          <li>External Third Parties as set out in the Glossary.</li>
          <li>
            Third parties to whom we may choose to sell, transfer, or merge
            parts of our business or our assets. Alternatively, we may seek to
            acquire other businesses or merge with them. If a change happens to
            our business, then the new owners may use your personal data in the
            same way as set out in this privacy notice.
          </li>
        </ul>
        <p>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </p>

        <h3 id="international-transfers">International transfers</h3>
        <p>We do not transfer your personal data outside the UK.</p>

        <h3 id="data-security">Data security</h3>
        <p>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </p>
        <p>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
        </p>

        <h3 id="data-retention">Data retention</h3>
        <h4>How long will you use my personal data for?</h4>
        <p>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
        </p>
        <p>
          Details of retention periods for different aspects of your personal
          data are available in our retention policy which you can request from
          us by <a href="/contact">contacting us</a>.
        </p>

        <h3 id="legal-rights">Your legal rights</h3>
        <p>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data. Please click on the links
          below to find out more about these rights:
        </p>
        <div className={styles.col6}>
          <ListGroup>
            <ListGroupItem
              href="#access"
              title="Request access to your personal data"
            />
            <ListGroupItem
              href="#correction"
              title="Request correction of your personal data"
            />
            <ListGroupItem
              href="#erasure"
              title="Request erasure of your personal data"
            />
            <ListGroupItem
              href="#processing"
              title="Object to processing of your personal data"
            />
            <ListGroupItem
              href="#restriction"
              title="Request restriction of processing your personal data"
            />
            <ListGroupItem
              href="#transfer"
              title="Request transfer of your personal data"
            />
            <ListGroupItem href="#withdraw" title="Right to withdraw consent" />
          </ListGroup>
          <div></div>
        </div>

        <p>
          If you wish to exercise any of the rights set out above, please{" "}
          <a href="/contact">contact us</a>.
        </p>

        <h4>No fee usually required</h4>
        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we may refuse to comply with your request in these
          circumstances.
        </p>

        <h4>What we may need from you</h4>
        <p>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </p>

        <h4>Time limit to respond</h4>
        <p>
          We try to respond to all legitimate requests within one month.
          Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>

        <h3 id="glossary">Glossary</h3>
        <h4>Lawful basis</h4>

        <DescriptiveList>
          <DescriptiveListItem title="Legitimate Interest">
            Means the interest of our business in conducting and managing our
            business to enable us to give you the best service/product and the
            best and most secure experience. We make sure we consider and
            balance any potential impact on you (both positive and negative) and
            your rights before we process your personal data for our legitimate
            interests. We do not use your personal data for activities where our
            interests are overridden by the impact on you (unless we have your
            consent or are otherwise required or permitted to by law). You can
            obtain further information about how we assess our legitimate
            interests against any potential impact on you in respect of specific
            activities by contacting us.
          </DescriptiveListItem>
          <DescriptiveListItem title="Performance of Contract">
            Means processing your data where it is necessary for the performance
            of a contract to which you are a party or to take steps at your
            request before entering into such a contract.
          </DescriptiveListItem>
          <DescriptiveListItem title="Comply with a legal or regulatory obligation">
            Means processing your personal data where it is necessary for
            compliance with a legal or regulatory obligation that we are subject
            to.
          </DescriptiveListItem>
        </DescriptiveList>

        <h4>Third parties</h4>
        <h5>Internal third parties</h5>
        <p>
          Other companies in the Wheelwrights Accounting Limited Group [acting
          as joint controllers or processors] and who are based in the UK.
        </p>

        <h5>External third parties</h5>
        <ul>
          <li>
            Service providers [acting as processors] based in the United Kingdom
            who provide IT and system administration services.
          </li>
          <li>
            Professional advisers [acting as processors or joint controllers]
            including lawyers, bankers, auditors and insurers based in the
            United Kingdom who provide consultancy, banking, legal, insurance
            and accounting services.
          </li>
          <li>
            HM Revenue &amp; Customs, regulators and other authorities [acting
            as processors or joint controllers] based in the United Kingdom who
            require reporting of processing activities in certain circumstances.
          </li>
        </ul>

        <h4>Your legal rights</h4>
        <p>You have the right to:</p>
        <DescriptiveList>
          <DescriptiveListItem
            id="access"
            title="Request access to your personal data (commonly known as a “data subject access request”)."
          >
            This enables you to receive a copy of the personal data we hold
            about you and to check that we are lawfully processing it.
          </DescriptiveListItem>
          <DescriptiveListItem
            id="correction"
            title="Request correction of the personal data that we hold about you."
          >
            This enables you to have any incomplete or inaccurate data we hold
            about you corrected, though we may need to verify the accuracy of
            the new data you provide to us.
          </DescriptiveListItem>
          <DescriptiveListItem
            id="erasure"
            title="Request erasure of your personal data"
          >
            This enables you to ask us to delete or remove personal data where
            there is no good reason for us continuing to process it. You also
            have the right to ask us to delete or remove your personal data
            where you have successfully exercised your right to object to
            processing (see below), where we may have processed your information
            unlawfully or where we are required to erase your personal data to
            comply with local law. Note, however, that we may not always be able
            to comply with your request of erasure for specific legal reasons
            which will be notified to you, if applicable, at the time of your
            request.
          </DescriptiveListItem>
          <DescriptiveListItem
            id="processing"
            title="Object to processing of your personal data"
          >
            Where we are relying on a legitimate interest (or those of a third
            party) and there is something about your particular situation which
            makes you want to object to processing on this ground as you feel it
            impacts on your fundamental rights and freedoms. You also have the
            right to object where we are processing your personal data for
            direct marketing purposes. In some cases, we may demonstrate that we
            have compelling legitimate grounds to process your information which
            override your rights and freedoms.
          </DescriptiveListItem>
          <DescriptiveListItem
            id="restriction"
            title="Request restriction of processing of your personal data"
          >
            This enables you to ask us to suspend the processing of your
            personal data in the following scenarios: (a) if you want us to
            establish the data's accuracy; (b) where our use of the data is
            unlawful but you do not want us to erase it; (c) where you need us
            to hold the data even if we no longer require it as you need it to
            establish, exercise or defend legal claims; or (d) you have objected
            to our use of your data but we need to verify whether we have
            overriding legitimate grounds to use it.
          </DescriptiveListItem>
          <DescriptiveListItem
            id="transfer"
            title="Request the transfer of your personal data to you or to a third party"
          >
            We will provide to you, or a third party you have chosen, your
            personal data in a structured, commonly used, machine-readable
            format. Note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you.
          </DescriptiveListItem>
          <DescriptiveListItem
            id="withdraw"
            title="Withdraw consent at any time where we are relying on consent to process your personal data"
          >
            However, this will not affect the lawfulness of any processing
            carried out before you withdraw your consent. If you withdraw your
            consent, we may not be able to provide certain products or services
            to you. We will advise you if this is the case at the time you
            withdraw your consent.
          </DescriptiveListItem>
        </DescriptiveList>
      </PageSection>
      <PageSection colour="dark">
        <h2 id="content">Content</h2>
        <ul>
          <li>
            This website is for information only. Unless otherwise stated it is
            not intended to offer advice and is not to be taken as, in any way,
            offering to sell any product or provide any service.
          </li>
          <li>
            We retain the copyright for the pages of this website and the
            material and information contained in those pages, with all rights
            reserved by us.
          </li>
          <li>
            The pages of this site may not, in whole or in part, be reproduced,
            copied stored, transmitted or used by any party, except for the
            purpose of downloading for private, non-commercial, viewing purposes
            or where we have given our prior written consent. You must not alter
            anything on this website and no material from this site may be used
            on any other website. No links may be created to this site without
            our prior written consent.
          </li>
          <li>
            We may make changes to the information contained within this site at
            any time without updating this site to reflect those changes. We
            will not be liable for errors, omissions or for information becoming
            out of date. If you are in any doubt as to the accuracy and currency
            of any information contained within the pages of this site, or you
            require any further information, you should{" "}
            <a href="/contact">contact us</a>.
          </li>
        </ul>
      </PageSection>
      <PageSection>
        <h2 id="downloads">Downloads</h2>
        <ul>
          <li>
            Any software is downloaded at your own risk. We do not warrant the
            suitability of any software which is downloaded and accept no
            liability for any problems with your computer that may arise as a
            result.
          </li>
        </ul>
      </PageSection>
      <PageSection colour="dark">
        <h2 id="liability">Liability</h2>
        <ul>
          <li>
            We will not be liable for any claims, penalties, losses, damages,
            costs or expenses arising from the use of or inability to use,
            interruption or availability of, this website, its operation or
            transmission, computer viruses or any unauthorised access to or
            alteration of the website. We do not warrant that the contents of
            this website are compatible with all computer systems and browsers.
          </li>
        </ul>
      </PageSection>
      <PageSection>
        <h2 id="emails">Emails</h2>
        <ul>
          <li>
            We do not guarantee that any email sent to us will be received or
            that the contents will remain private during transmission. If you
            are concerned about this please consider other means of
            communication. You are responsible for ensuring any electronic
            message or information you send to us is free from any virus or
            defect that may harm our systems in any way.
          </li>
        </ul>
      </PageSection>
    </div>
  );
}

export default Legals;
