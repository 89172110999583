import { useId } from "react";
import { NavLink, To } from "react-router-dom";
import styles from "./Navigation.module.scss";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IDropdownNavItemProps {
  label: string;
  right?: boolean;
  links: ReadonlyArray<IDropdownNavLinkProps>;
}

interface IDropdownNavLinkProps {
  to: To;
  label: string;
}

function DropdownNavItem(props: IDropdownNavItemProps) {
  const id = useId();
  let className = styles.navItemDropdown;

  if (props.right) {
    className += ` ${styles.right}`;
  }

  return (
    <div id={id} key={`${id}-nav-dropdown`} className={className}>
      <button id={`${id}-button`} className={styles.dropdownButton}>
        {props.label} <FontAwesomeIcon icon={faAngleDown} />
      </button>
      <div className={styles.dropdownContent}>
        {props.links.map((link) => {
          return (
            <NavLink key={`${id}-dropdown-link-${link.label}`} to={link.to}>
              {link.label}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

export default DropdownNavItem;
