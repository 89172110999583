export const primaryColour = "#6F893E";

export const name = "Wheelwrights Accounting Limited";
export const address = "2 The Wheelwrights, Blackham, East Sussex, TN3 9TS";
export const telephone = "07542 384 391";
export const email = "contact@wheelwrightsaccounting.co.uk";

export const footerText =
  "Experienced firm of Chartered Certified Accountants and Business Advisors located near Tunbridge Wells";

export const reCAPTCHASiteKey = "6LeGiCciAAAAAH5slxl8RgoQBHWm4nmtWBan1CCg";
export const emailerSite =
  "https://wheelwrightsaccountingemailer.azurewebsites.net";

export interface ITestimonial {
  name: string;
  testimonial: string;
}

export const testimonials: ITestimonial[] = [
  {
    name: "John Doe",
    testimonial: "Wheelwrights accounting have been very professional",
  },
  {
    name: "Jane Doe",
    testimonial: "There are no better accountants",
  },
  {
    name: "Jane Doe",
    testimonial:
      "There are no better accountants, There are no better accountants, There are no better accountants, There are no better accountants, There are no better accountants, There are no better accountants",
  },
];

export interface ILink {
  name: string;
  url: string;
}

export const footerLinks: ReadonlyArray<ILink> = [
  { name: "Home", url: "/" },
  { name: "About", url: "/about" },
  { name: "Contact", url: "/contact" },
  { name: "Legals", url: "/legals" },
  { name: "Site Map", url: "/site-map" },
  { name: "Services", url: "/services" },
  // { name: "Resources", url: "/resources" },
];
