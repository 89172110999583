import PageSection from "../Components/PageSection";
import styles from "./Site.module.scss";
import profile from "../Images/nicholas.jpg";

function About() {
  return (
    <div className={styles.about}>
      <PageSection gutterColour="#FFF">
        <h2>Who are we?</h2>
        <p>
          A Chartered Certified Accountant with many years experience in
          accountancy and advisory services including accounts, vat, payroll,
          tax return preparation and tax planning for small businesses whether
          as a company, sole trader, partnership or LLP.
        </p>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.profiles}>
          <div>
            <img src={profile} alt="Nicholas Russ" />
            <h2>Nicholas Russ</h2>
            <h3>Director</h3>
            <p>
              I qualified as a Chartered Certified Accountant in November 1999,
              becoming a Fellow with the Association in November 2004. Whilst a
              lot of my time is focused on work I enjoy walking and spending
              time with the family.
            </p>
          </div>
        </div>
      </PageSection>
      {/* <PageSection>
        <div className={styles.testimonials}>
          <h2>Testimonials</h2>
          <Slideshow>
            {testimonials.map((x, i) => (
              <Slide key={`testimonial-${i}-${x.name}`} title={x.name}>
                <q>{x.testimonial}</q>
              </Slide>
            ))}
          </Slideshow>
        </div>
      </PageSection> */}
    </div>
  );
}

export default About;
