import { Link } from "react-router-dom";
import styles from "./Site.module.scss";
import Image404 from "../Images/404.png";

function NoMatch() {
  return (
    <div className={styles.noMatch}>
      <div>
        <img src={Image404} />
      </div>
      <h2>Page not found</h2>
      <p>
        Oops! The page you are looking for does not exist. It might have been
        moved or deleted.
      </p>
      <br />
      <Link to="/">Back to home</Link>
      <br />
    </div>
  );
}

export default NoMatch;
