import { ErrorMessage, Field, Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import FieldWithError from "./Calculators/Calculator/FieldWithError";
import styles from "./ContactForm.module.scss";
import indexStyles from "../index.module.scss";
import TextAreaFieldWithError from "./Calculators/Calculator/TextAreaFieldWithError";
import { useState } from "react";
import { emailerSite, reCAPTCHASiteKey } from "../Constants/Strings";

interface IFormErrors {
  name?: string;
  telephone?: string;
  email?: string;
  subject?: string;
  message?: string;
  ReCAPTCHA?: string;
}

interface IFormState {
  name?: string;
  telephone?: string;
  email?: string;
  subject?: string;
  message?: string;
  ReCAPTCHA?: string;
}

const initialValues: IFormState = {
  name: "",
  telephone: "",
  email: "",
  subject: "",
  message: "",
  ReCAPTCHA: "",
};

interface IContactEmail {
  name?: string;
  telephone?: string;
  email?: string;
  subject?: string;
  message?: string;
}

function ContactForm() {
  const [hasSent, setHasSent] = useState<boolean>(false);

  async function sendEmail(email: IContactEmail) {
    const response = await fetch(`${emailerSite}/api/SendEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }

    return body;
  }

  return (
    <div className={styles.contactForm}>
      <h2>Contact form</h2>
      <p>
        Send us your questions and queries using the quick contact form below...
      </p>
      {!hasSent ? (
        <Formik<IFormState>
          initialValues={initialValues}
          validate={(values) => {
            const errors: IFormErrors = {};

            if (values.email == undefined || values.email.length <= 0) {
              errors.email = "Required";
            }

            if (values.message == undefined || values.message.length <= 0) {
              errors.message = "Required";
            }

            if (values.name == undefined || values.name.length <= 0) {
              errors.name = "Required";
            }

            if (values.subject == undefined || values.subject.length <= 0) {
              errors.subject = "Required";
            }

            if (values.telephone == undefined || values.telephone.length <= 0) {
              errors.telephone = "Required";
            }

            if (values.ReCAPTCHA == undefined || values.ReCAPTCHA.length <= 0) {
              errors.ReCAPTCHA = "ReCAPTCHA is required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            await sendEmail({
              ...values,
            });

            const oldCaptcha = values.ReCAPTCHA;
            setSubmitting(false);
            resetForm();
            values.ReCAPTCHA = oldCaptcha;
            setHasSent(true);
          }}
        >
          {({ isSubmitting, setFieldValue, touched, errors }) => (
            <Form>
              <div className={styles.formContainer}>
                <div className={styles.contactInfo}>
                  <label htmlFor="name">Name</label>
                  <Field
                    name="name"
                    placeholder="Name"
                    component={FieldWithError}
                  />

                  <label htmlFor="telephone">Telephone</label>
                  <Field
                    type="tel"
                    name="telephone"
                    placeholder="Telephone"
                    component={FieldWithError}
                  />

                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    component={FieldWithError}
                  />

                  <ReCAPTCHA
                    sitekey={reCAPTCHASiteKey}
                    onChange={(token) => {
                      setFieldValue("ReCAPTCHA", token);
                    }}
                    className={
                      touched["ReCAPTCHA"] && errors["ReCAPTCHA"]
                        ? indexStyles.error
                        : undefined
                    }
                  />
                  <ErrorMessage
                    name="ReCAPTCHA"
                    component="div"
                    className={indexStyles.error}
                  />
                </div>
                <div className={styles.message}>
                  <label htmlFor="subject">Subject</label>
                  <Field
                    name="subject"
                    placeholder="Subject"
                    component={FieldWithError}
                  />

                  <label htmlFor="subject">Message</label>
                  <Field
                    name="message"
                    placeholder="Message"
                    component={TextAreaFieldWithError}
                  />
                </div>
              </div>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Sending" : "Send"}
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <div>
          <h2>Thank you!</h2>
          <p>Your message has been sent, we will get back to you shortly</p>
        </div>
      )}
    </div>
  );
}

export default ContactForm;
