import { Routes, Route } from "react-router-dom";
import styles from "./Site.module.scss";

import Home from "./Home";
import About from "./About";
import NoMatch from "./NoMatch";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation/Navigation";
import Contact from "./Contact";
import ServicesOverview from "./Services/Overview";
import AccountingServices from "./Services/AccountingServices";
import CompanySecretarial from "./Services/CompanySecretarial";
import BusinessSupport from "./Services/BusinessSupport";
import FinancialManagement from "./Services/FinancialManagement";
import Legals from "./Legals";
import SiteMap from "./SiteMap";

function App() {
  return (
    <div className={styles.app}>
      <Header />
      <Navigation />
      <div className={styles.content}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="legals" element={<Legals />} />
          <Route path="site-map" element={<SiteMap />} />
          <Route path="*" element={<NoMatch />} />

          {/* Services */}
          <Route path="services/overview" element={<ServicesOverview />} />
          <Route path="services" element={<ServicesOverview />} />
          <Route
            path="services/accounting-services"
            element={<AccountingServices />}
          />
          <Route
            path="services/company-secretarial"
            element={<CompanySecretarial />}
          />
          <Route
            path="services/business-support"
            element={<BusinessSupport />}
          />
          <Route
            path="services/financial-management"
            element={<FinancialManagement />}
          />

          {/* Resources */}
          {/* <Route path="resources/overview" element={<ResourcesOverview />} />
          <Route path="resources" element={<ResourcesOverview />} /> */}

          {/* Resources - Your Business */}
          {/* <Route path="resources/your-business" element={<YourBusiness />} />
          <Route
            path="resources/your-business/cis-tax-deductions"
            element={<CISTaxDeductions />}
          />
          <Route
            path="resources/your-business/gross-profit"
            element={<GrossProfit />}
          />
          <Route
            path="resources/your-business/more-profit"
            element={<MoreProfit />}
          /> */}

          {/* Resources - Your Money */}
          {/* <Route path="resources/your-money" element={<YourMoney />} />
          <Route
            path="resources/your-money/personal-loan"
            element={<PersonalLoan />}
          /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
