import { faPhone } from "@fortawesome/free-solid-svg-icons";
import CallToAction from "../../Components/CallToAction";
import PageSection from "../../Components/PageSection";
import banner from "../../Images/ServicesBanner.png";
import styles from "./Services.module.scss";

function FinancialManagement() {
  return (
    <div className={styles.financialManagement}>
      <PageSection
        backgroundImageUrl={banner}
        className={styles.servicesBackground}
        gutterColour="#999999"
      >
        <div className={styles.title}>
          <h1>Financial Management</h1>
          <p>
            We can help ensure that your personal finances are managed as
            tax-efficiently as possible.
          </p>
        </div>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.page}>
          <div className={styles.left}>
            <h3>We can advise on:</h3>
            <ul>
              <li>Personal tax planning</li>
              <li>Extracting profit from your business</li>
              <li>Remuneration strategies</li>
              <li>Tax-efficient savings and investments</li>
              <li>Planning for a comfortable retirement</li>
              <li>Estate and inheritance tax planning</li>
              <li>Gifting strategies</li>
            </ul>
          </div>
          <div className={styles.right}>
            <CallToAction
              to="/contact"
              title="Contact Us"
              description="Get in touch today and let us help you"
              icon={faPhone}
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
}

export default FinancialManagement;
