import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, To } from "react-router-dom";
import styles from "./CallToAction.module.scss";

export interface ICallToActionProps {
  icon: IconProp;
  title: string;
  description?: string;
  to: To;
}

function CallToAction(props: ICallToActionProps) {
  return (
    <div>
      <Link className={styles.callToAction} to={props.to}>
        <div>
          <FontAwesomeIcon icon={props.icon} size="2x" />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{props.title}</div>
          <p className={styles.description}>{props.description}</p>
        </div>
      </Link>
    </div>
  );
}

export default CallToAction;
