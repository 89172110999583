import PageSection from "../Components/PageSection";
import styles from "./Site.module.scss";
import banner from "../Images/BannerWheel.png";
import Card from "../Components/Card";
import { primaryColour } from "../Constants/Strings";
import Cards from "../Components/Cards";
import {
  faHandHoldingDollar,
  faCoins,
  faUserTie,
  faTableCells,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Home() {
  return (
    <div className={styles.home}>
      <PageSection
        backgroundImageUrl={banner}
        className={styles.background}
        gutterColour="#436015"
      >
        <div className={styles.welcome}>
          <h1>Welcome to Wheelwrights Accounting Limited</h1>
          <h3>
            We are an experienced firm of Chartered Certified Accountants and
            Business Advisors located near Tunbridge Wells.
          </h3>
          <p>
            Our friendly team can provide a wide range of accountancy and
            advisory services tailored to your individual needs. We help clients
            in various industries and all sizes from individuals, start-ups and
            SMEs.
          </p>
          <br />
          <br />
          <p>The right choice for your business</p>
          <div className={styles.bulletList}>
            <span>
              <FontAwesomeIcon icon={faCircle} /> Free Initial Consultations
            </span>
            <span>
              <FontAwesomeIcon icon={faCircle} /> Competitive Fees
            </span>
            <span>
              <FontAwesomeIcon icon={faCircle} /> Approachable Team
            </span>
          </div>
        </div>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.services}>
          <h2>Services</h2>
          <p>
            We offer a range of high quality accounting, financial and business
            services. Click on the links below for more information
          </p>
          <div className={styles.linksToServices}>
            <Cards>
              <Card
                title="Accounting services"
                to="/services/accounting-services"
                background={{
                  type: "icon",
                  backgroundColour: primaryColour,
                  icon: faTableCells,
                }}
              />
              <Card
                title="Company secretarial"
                to="/services/company-secretarial"
                background={{
                  type: "icon",
                  backgroundColour: primaryColour,
                  icon: faUserTie,
                }}
              />
              <Card
                title="Business support"
                to="/services/business-support"
                background={{
                  type: "icon",
                  backgroundColour: primaryColour,
                  icon: faHandHoldingDollar,
                }}
              />
              <Card
                title="Financial management"
                to="/services/financial-management"
                background={{
                  type: "icon",
                  backgroundColour: primaryColour,
                  icon: faCoins,
                }}
              />
            </Cards>
          </div>
        </div>
      </PageSection>
      {/* <PageSection>
        <div className={styles.resources}>
          <h2>Resources</h2>
          <p>
            Explore our resources to support your business, money and gain
            strategic insights to grow your business. Use our calculators to
            quick determine your affordability. Click on the below for more
            information
          </p>
          <div className={styles.linksToResources}>
            <Cards>
              <Card
                title="Your business"
                description="Interactive with our useful resources to provide you with quick advice and calculations via our interactive tools."
                to="/resources/your-business"
                isDark
                background={{
                  type: "icon",
                  backgroundColour: primaryColour,
                  icon: faTableCells,
                }}
              />
              <Card
                title="Your money"
                description="Interactive with our useful resources to provide you with quick advice and calculations via our interactive tools."
                to="/resources/your-money"
                isDark
                background={{
                  type: "icon",
                  backgroundColour: primaryColour,
                  icon: faBookOpen,
                }}
              />
            </Cards>
          </div>
        </div>
      </PageSection> */}
      {/* <PageSection>
        <div className={styles.testimonials}>
          <h2>Testimonials</h2>
          <Slideshow>
            {testimonials.map((x, i) => (
              <Slide key={`testimonial-${i}-${x.name}`} title={x.name}>
                <q>{x.testimonial}</q>
              </Slide>
            ))}
          </Slideshow>
        </div>
      </PageSection> */}
    </div>
  );
}

export default Home;
