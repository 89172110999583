import { ErrorMessage, Field as FormikField } from "formik";
import styles from "../../../index.module.scss";

function TextAreaFieldWithError({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: any) {
  function _getErrorClass() {
    if (touched[field.name] && errors[field.name]) {
      return styles.error;
    }

    return undefined;
  }

  return (
    <>
      <FormikField
        {...field}
        {...props}
        className={_getErrorClass()}
        component="textarea"
      />
      <ErrorMessage
        name={field.name}
        component="div"
        className={styles.error}
      />
    </>
  );
}

export default TextAreaFieldWithError;
