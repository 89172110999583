import {
  faCoins,
  faHandHoldingDollar,
  faTableCells,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import Card from "../../Components/Card";
import Cards from "../../Components/Cards";
import PageSection from "../../Components/PageSection";
import { primaryColour } from "../../Constants/Strings";
import banner from "../../Images/ServicesBanner.png";
import styles from "./Services.module.scss";

function ServicesOverview() {
  return (
    <div className={styles.overview}>
      <PageSection
        backgroundImageUrl={banner}
        className={styles.servicesBackground}
        gutterColour="#999999"
      >
        <div className={styles.title}>
          <h1>Services</h1>
          <p>
            We offer a range of high quality accounting, financial and business
            services. Click on the links below for more information:
          </p>
        </div>
      </PageSection>
      <PageSection colour="dark">
        <div className={styles.linksToServices}>
          <Cards>
            <Card
              title="Accounting services"
              description="Offering you peace of mind that your accounts and returns are in order."
              to="/services/accounting-services"
              background={{
                type: "icon",
                backgroundColour: primaryColour,
                icon: faTableCells,
              }}
            />
            <Card
              title="Company secretarial"
              description="Providing peace of mind, by taking the hard work off your hands."
              to="/services/company-secretarial"
              background={{
                type: "icon",
                backgroundColour: primaryColour,
                icon: faUserTie,
              }}
            />
            <Card
              title="Business support"
              description="Working with you to ensure the best possible chance of success."
              to="/services/business-support"
              background={{
                type: "icon",
                backgroundColour: primaryColour,
                icon: faHandHoldingDollar,
              }}
            />
            <Card
              title="Financial management"
              description="Ensuring that your personal finances are managed as tax-efficiently as possible."
              to="/services/financial-management"
              background={{
                type: "icon",
                backgroundColour: primaryColour,
                icon: faCoins,
              }}
            />
          </Cards>
        </div>
      </PageSection>
    </div>
  );
}

export default ServicesOverview;
