import DescriptiveListItem from "./DescriptiveListItem";
import styles from "./DescriptiveList.module.scss";

interface IDescriptiveListProps {
  children:
    | React.ReactElement<typeof DescriptiveListItem>
    | React.ReactElement<typeof DescriptiveListItem>[];
}

function DescriptiveList(props: IDescriptiveListProps) {
  return <dl className={styles.descriptiveList}>{props.children}</dl>;
}

export default DescriptiveList;
