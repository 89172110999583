import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Card.module.scss";

export interface ICardImageBackgroundProps {
  type: "image";
  imgSrc: string;
}

export interface ICardIconBackgroundProps {
  type: "icon";
  backgroundColour: string;
  icon: IconProp;
}

interface ICardBackgroundProps {
  background: ICardImageBackgroundProps | ICardIconBackgroundProps;
}

function CardBackground(props: ICardBackgroundProps) {
  if (props.background.type === "icon") {
    return (
      <div
        className={styles.background}
        style={{ backgroundColor: props.background.backgroundColour }}
      >
        <FontAwesomeIcon icon={props.background.icon} size="3x" />
      </div>
    );
  }

  return (
    <div className={styles.background}>
      <img src={props.background.imgSrc} />
    </div>
  );
}

export default CardBackground;
