import { Link, To } from "react-router-dom";
import styles from "./Card.module.scss";
import CardBackground, {
  ICardIconBackgroundProps,
  ICardImageBackgroundProps,
} from "./CardBackground";

export interface ICardProps {
  isDark?: boolean;
  background: ICardImageBackgroundProps | ICardIconBackgroundProps;
  title: string;
  description?: string;
  to: To;
}

function Card(props: ICardProps) {
  let cardClass = styles.card;

  if (props.isDark) {
    cardClass += " " + styles.dark;
  }

  if (props.description != undefined) {
    cardClass += " " + styles.lg;
  }

  return (
    <Link className={cardClass} to={props.to}>
      <CardBackground background={props.background} />
      <div className={styles.content}>
        <div className={styles.title}>{props.title}</div>
        <p className={styles.description}>{props.description}</p>
      </div>
    </Link>
  );
}

export default Card;
