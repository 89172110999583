import styles from "./PageSection.module.scss";

interface IPageSectionProps {
  colour?: "light" | "dark";
  backgroundImageUrl?: string;
  className?: string | undefined;
  gutterColour?: string;
}

function PageSection(props: React.PropsWithChildren<IPageSectionProps>) {
  function _getPageSectionClass(): string {
    if (props.backgroundImageUrl !== undefined) {
      return styles.pageSection;
    }
    if (props.colour === "dark") {
      return styles.pageSection + " " + styles.dark;
    }

    return styles.pageSection + " " + styles.light;
  }

  const backgroundClassName = !props.className
    ? styles.pageSectionBackground
    : `${styles.pageSectionBackground} ${props.className}`;

  return (
    <div
      className={styles.pageSectionGutter}
      style={{ backgroundColor: props.gutterColour }}
    >
      <div
        className={backgroundClassName}
        style={
          props.backgroundImageUrl
            ? { backgroundImage: `url(${props.backgroundImageUrl})` }
            : {}
        }
      >
        <div className={_getPageSectionClass()}>{props.children}</div>
      </div>
    </div>
  );
}

export default PageSection;
