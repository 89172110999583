import styles from "./ListGroup.module.scss";

interface IListGroupItemProps {
  title: string;
  href: string;
}

function ListGroupItem(props: IListGroupItemProps) {
  return (
    <li>
      <a href={props.href} className={styles.listGroupItem}>
        {props.title}
      </a>
    </li>
  );
}

export default ListGroupItem;
