import DropdownNavItem from "./DropdownNavItem";
import styles from "./Navigation.module.scss";
import NavItem from "./NavItem";

function Navigation() {
  return (
    <div className={styles.navigation}>
      <div className={styles.navigationContainer}>
        <NavItem to="/" label="Home" />
        <DropdownNavItem
          label="Services"
          links={[
            {
              to: "/services/overview",
              label: "Overview",
            },
            {
              to: "/services/accounting-services",
              label: "Accounting services",
            },
            {
              to: "/services/company-secretarial",
              label: "Company secretarial",
            },
            {
              to: "/services/business-support",
              label: "Business support",
            },
            {
              to: "/services/financial-management",
              label: "Financial management",
            },
          ]}
        />
        {/* <DropdownNavItem
          label="Resources"
          links={[
            {
              to: "/resources/overview",
              label: "Overview",
            },
            {
              to: "/resources/your-business",
              label: "Your business",
            },
            {
              to: "/resources/your-money",
              label: "Your money",
            },
          ]}
        /> */}
        <NavItem to="/about" label="About" />
        <NavItem to="/contact" label="Contact" right={true} />
      </div>
    </div>
  );
}

export default Navigation;
