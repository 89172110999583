import { email, name, telephone } from "../Constants/Strings";
import styles from "./Header.module.scss";
import logo from "../Images/Logo.png";
import {
  faPhone,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to="/">
          <img src={logo} alt="Wheelwrights Accounting Limited" />
        </Link>
        <div className={styles.contactDetails}>
          <span>
            <FontAwesomeIcon icon={faClock} /> Monday-Friday: 9am-5pm
          </span>
          <a href={`tel:+${telephone}`}>
            <FontAwesomeIcon icon={faPhone} /> {telephone}
          </a>
          <a href={`mailto:${email}`}>
            <FontAwesomeIcon icon={faEnvelope} /> {email}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
